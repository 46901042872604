const customerComments = [
  {
    comment:
      "Vision Subsea supported HDL with in-service ROV inspection of a water storage reservoir for the City of Palmer. Joshua and Vision Subsea were courteous, professional, and truly went the extra step to ensure that the project was thoroughly completed. They would be my first choice for any future work.",
    name: "Chris",
    active: true,
  },
  {
    comment:
      "Vision Subsea was used by R&M to assist the City of Homer with a low-cost damage assessment of a fender after a vessel hit the dock. Considering the area of damage was known, it was a good tool to ensure that there were no hidden surprises underwater. It allowed the City avoid pulling the fender and to continue using the dock while the necessary replacement parts were fabricated.",
    name: "Kim",
    active: false,
  },
  {
    comment:
      "VISION SUBSEA did an amazing job checking our Aquaculture farm for us. We raise oysters in 180 feet deep water which we have never been able to access. Josh took the ROV down our mooring lines to the anchors at 180 feet with precision, we were able to inspect all of our moorings quickl and efficiently. This gave us a lot of confidence seeing our anchors in place on the bottom of the ocean holding our farm properly. Thanks Josh and VISION SUBSEA for providing the tools and experience to inspect things at depth in Kachemak Bay and beyond, we look forward to having you back so we can see what else is down there.",
    name: "Weatherly",
    active: false,
  },
  {
    comment:
      "Joshua Hankin-Foley is experienced in leading successful offshore projects by focusing on safety, organization, preparation, operational efficiency, and aggressive cost, management. He has translated his experience and extended this commitment to high level performance to his current position at VISION SUBSEA.",
    name: "John",
    active: false,
  },
  {
    comment:
      "VISION SUBSEA and their ROV saved the day when my buoy came off of my boat mooring. The ROV is a super useful tool when you need to see what's going on under the ocean. After finding and recovering the mooring line, they completed an inspection and sent me a video of the now functional mooring from the buoy all the way down to the anchor. Thanks for everything!",
    name: "Alison",
    active: false,
  },
  {
    comment:
      "My crew was impressed with the ROV training that VISION SUBSEA provided. They came up with some creative solutions for options that our particular model did not possess. The day of training it rained hard the night before making the water in our harbor murky and hard to see the ROV underwater. He made the suggestion to use the local pool which worked out great for depth and directional practice. It made us aware of the difficulty of going straight up and down while keeping the ROV level. It helped out the crew a lot to get familiar in a controlled environment to fine tune and learn the capabilities of our ROV.",
    name: "Matt",
    active: false,
  },
];

export default customerComments;






